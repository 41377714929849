/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { IconCircle } from "../../icons/IconCircle";
import { ButtonBase } from "../ButtonBase";

export const Button = ({
  size,
  color,
  icon,
  className,
  buttonBaseLabelClassName,
  buttonBaseText = "Button",
  buttonBaseSizeSmIconNoneClassName,
  buttonBaseLabelClassNameOverride,
  buttonBaseIcon = <IconCircle className="!relative !w-6 !h-6" color="#7C3AED" />,
}) => {
  return (
    <div
      className={`inline-flex items-center rounded-lg justify-center relative ${color === "primary" && ["leading", "only", "trailing"].includes(icon) ? "overflow-hidden" : ""
        } ${color === "primary"
          ? "bg-primary-600"
          : color === "secondary-white"
            ? "bg-white"
            : color === "secondary-color"
              ? "bg-primary-100"
              : ""
        } ${className}`}
    >
      <ButtonBase
        className={buttonBaseSizeSmIconNoneClassName}
        divClassName={
          (color === "link-color" && icon === "trailing") ||
            (color === "secondary-color" && icon === "trailing") ||
            (color === "secondary-white" && icon === "trailing") ||
            (color === "tertiary" && icon === "trailing")
            ? "!text-primary-600"
            : undefined
        }
        essentialIcon84Color={
          (color === "link-color" && icon === "trailing" && size === "lg") ||
            (color === "link-color" && icon === "trailing" && size === "md") ||
            (color === "link-color" && icon === "trailing" && size === "sm") ||
            (color === "link-color" && icon === "trailing" && size === "xs") ||
            (color === "secondary-color" && icon === "trailing" && size === "lg") ||
            (color === "secondary-color" && icon === "trailing" && size === "md") ||
            (color === "secondary-color" && icon === "trailing" && size === "sm") ||
            (color === "secondary-color" && icon === "trailing" && size === "xs") ||
            (color === "secondary-white" && icon === "trailing" && size === "lg") ||
            (color === "secondary-white" && icon === "trailing" && size === "md") ||
            (color === "secondary-white" && icon === "trailing" && size === "sm") ||
            (color === "secondary-white" && icon === "trailing" && size === "xs") ||
            (color === "tertiary" && icon === "trailing" && size === "lg") ||
            (color === "tertiary" && icon === "trailing" && size === "md") ||
            (color === "tertiary" && icon === "trailing" && size === "sm") ||
            (color === "tertiary" && icon === "trailing" && size === "xs")
            ? "#7C3AED"
            : (color === "link-white" && icon === "trailing" && size === "lg") ||
              (color === "link-white" && icon === "trailing" && size === "md") ||
              (color === "link-white" && icon === "trailing" && size === "sm") ||
              (color === "link-white" && icon === "trailing" && size === "xs") ||
              (color === "primary" && icon === "trailing" && size === "lg") ||
              (color === "primary" && icon === "trailing" && size === "md") ||
              (color === "primary" && icon === "trailing" && size === "sm") ||
              (color === "primary" && icon === "trailing" && size === "xs")
              ? "white"
              : undefined
        }
        essentialIcon84Stroke={
          (color === "link-color" && icon === "only" && size === "lg") ||
            (color === "link-color" && icon === "only" && size === "md") ||
            (color === "link-color" && icon === "only" && size === "sm") ||
            (color === "link-color" && icon === "only" && size === "xs") ||
            (color === "secondary-color" && icon === "only" && size === "lg") ||
            (color === "secondary-color" && icon === "only" && size === "md") ||
            (color === "secondary-color" && icon === "only" && size === "sm") ||
            (color === "secondary-color" && icon === "only" && size === "xs") ||
            (color === "secondary-white" && icon === "only" && size === "lg") ||
            (color === "secondary-white" && icon === "only" && size === "md") ||
            (color === "secondary-white" && icon === "only" && size === "sm") ||
            (color === "secondary-white" && icon === "only" && size === "xs") ||
            (color === "tertiary" && icon === "only" && size === "lg") ||
            (color === "tertiary" && icon === "only" && size === "md") ||
            (color === "tertiary" && icon === "only" && size === "sm") ||
            (color === "tertiary" && icon === "only" && size === "xs")
            ? "#7C3AED"
            : (color === "link-white" && icon === "only" && size === "lg") ||
              (color === "link-white" && icon === "only" && size === "md") ||
              (color === "link-white" && icon === "only" && size === "sm") ||
              (color === "link-white" && icon === "only" && size === "xs") ||
              (color === "primary" && icon === "only" && size === "lg") ||
              (color === "primary" && icon === "only" && size === "md") ||
              (color === "primary" && icon === "only" && size === "sm") ||
              (color === "primary" && icon === "only" && size === "xs")
              ? "white"
              : undefined
        }
        icon={icon === "trailing" ? "trailing" : icon === "leading" ? "leading" : icon === "only" ? "only" : "none"}
        iconCircleColor={
          (color === "link-color" && icon === "trailing" && size === "xl") ||
            (color === "secondary-color" && icon === "trailing" && size === "xl") ||
            (color === "secondary-white" && icon === "trailing" && size === "xl") ||
            (color === "tertiary" && icon === "trailing" && size === "xl")
            ? "#7C3AED"
            : icon === "trailing" && size === "xl" && ["link-white", "primary"].includes(color)
              ? "white"
              : undefined
        }
        iconCircleStroke={
          (color === "link-color" && icon === "only" && size === "xl") ||
            (color === "secondary-color" && icon === "only" && size === "xl") ||
            (color === "secondary-white" && icon === "only" && size === "xl") ||
            (color === "tertiary" && icon === "only" && size === "xl")
            ? "#7C3AED"
            : icon === "only" && size === "xl" && ["link-white", "primary"].includes(color)
              ? "white"
              : undefined
        }
        labelClassName={`${buttonBaseLabelClassName} ${buttonBaseLabelClassNameOverride}`}
        override={buttonBaseIcon}
        size={size === "md" ? "md" : size === "xs" ? "xs" : size === "xl" ? "xl" : size === "lg" ? "lg" : "sm"}
        text={buttonBaseText}
      />
    </div>
  );
};

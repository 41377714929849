/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const WithThePowerOf = ({ className }) => {
  return (
    <div className={`relative w-[243px] h-[66px] ${className}`}>
      <p className="-top-px left-0 absolute w-[243px] font-normal [font-family:'Source_Sans_Pro',Helvetica] text-base text-center text-neutral-700 leading-[22px] tracking-[0]">
        With the power of language models, we sort candidates based on your preferences, offering you personalized
        candidate recommendations.
      </p>
    </div>
  );
};

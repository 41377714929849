import React from "react";
import { Button } from "../../components/Button";
import { WithThePowerOf } from "../../components/WithThePowerOf";
import { EssentialIcon105 } from "../../icons/EssentialIcon105";
import butterflyImage from '../../images/Butterfly-Logo-Transparent-2048x2048.png';
import teamFill from '../../images/team-fill.svg'; // Import the local image
import searchGlobe from '../../images/search-globe.svg'; // Import the local image
import barChartFill from '../../images/bar-chart-fill.svg'; // Import the local image


export const Desktop = () => {
  return (

    <div className="bg-white w-full">
      <div className="bg-white w-full">

        {/* LANDING FRAME */}
        {/* Faded Light Blue - Background Image */}
        <img
          className="top-0 left-0 absolute w-full h-full min-h-[1000px] max-md:min-h-[1090px] object-cover"
          alt="Bg"
          src="https://c.animaapp.com/GnD7RgU1/img/bg.svg"
        />

        {/* Blush Circles Background for Landing Page */}
        <img
          className="top-0 left-0 absolute w-full h-full max-md:max-h-[900px] object-cover"
          alt="Blush circles"
          src="https://c.animaapp.com/GnD7RgU1/img/blush-circles-gradient-group.png"
        />
        <div className="relative w-full h-screen min-h-[1000px]">
          {/* Bands */}
          <div className="-z-50">
            {/* Right Bands */}
            <div>
              {/* Light Blue Band - 1st band right */}
              <div className="right-0 right-band-1 skewed-parallelogram-right absolute"></div>
              {/* Dark Blue Band - 2nd band right*/}
              <div className="right-0 right-band-2 skewed-parallelogram-right absolute"></div>
              {/* Violet Band - 3rd band right*/}
              <div className="right-0 right-band-3 skewed-parallelogram-right absolute"></div>
            </div>

            {/* Left Bands */}
            <div className="">
              {/* Dark Orange Band - 1st band left*/}
              <div className="left-0 left-band-1 skewed-parallelogram-left absolute"></div>
              {/* Light Orange Band - 2nd band left*/}
              <div className="left-0 left-band-2 skewed-parallelogram-left absolute"></div>
              {/* Yellow Band - 3rd band left*/}
              <div className="left-0 left-band-3 skewed-parallelogram-left absolute"></div>
            </div>
          </div>
          <img
            className="-top-4 -left-4 absolute w-[12%] min-w-[175px] max-md:min-w-[145px]"
            alt="Butterflies logo"
            src={butterflyImage}
          />
          <div className="top-1/4 max-md:top-[18%] left-[33%] max-xl:left-[25%] absolute flex justify-center items-center mx-auto w-[40%] max-md:w-[55%] md:min-w-[540px]">
            {/* This version is having the element center aligned in absolute positioning. Trick: https://tinyurl.com/cra3a6by | https://tinyurl.com/mrx8bb37 */}
            {/* <div className="top-1/4 max-md:top-[18%] left-[50%] absolute flex justify-center items-center mx-auto -ml-[25%] w-[40%] max-md:w-[55%] md:min-w-[540px] transform"> */}
            {/* <div className="top-1/4 max-md:top-[18%] left-[50%] absolute flex justify-center items-center mx-auto -ml-[22%] w-[40%] max-md:w-[55%] md:min-w-[540px] transform"> */}
            {/* <div className="flex justify-center items-center w-[20%]">
              <img
                className="mt-4 w-full"
                alt="Butterflies logo"
                src={butterflyImage}
              />
            </div> */}
            <div className="w-full">
              <h1 className="font-display-xl-bold [font-style:var(--display-xl-bold-font-style)] font-[number:var(--display-xl-bold-font-weight)] text-[60px] text-neutral-900 max-md:text-[55px] leading-[var(--display-xl-bold-line-height)] max-md:leading-[1.3em] tracking-[var(--display-xl-bold-letter-spacing)]">
                Finding Students...
              </h1>
              <p className="justify-start mt-3 font-[410] [font-family:'Inter',Helvetica] text-[#3a465b] text-lg max-md:text-base max-md:text-wrap leading-7 tracking-[0]">
                From the mass sending of emails to the endless stream of resumes,
                shouldn&#39;t it be easier to find the best students for
                specific roles?
              </p>
              <p className="mt-7 max-md:mt-5 max-md:mb-2 font-[500] [font-family:'Inter',Helvetica] text-[#ed8205] text-lg leading-7 tracking-[0]">
                That’s why we’re creating{" "}
                <a className="text-current visited:text-current no-underline" href="https://www.candela.fit/" target="_blank">
                  <span className="font-semibold underline">Candela</span>
                </a>.
              </p>
              <div className="flex xl:flex-row flex-col max-xl:justify-center max-xl:items-center xl:space-x-4 mt-7">
                {/* <Button
                  buttonBaseLabelClassName="text-white"
                  buttonBaseText="Notify Me of the Beta Release!"
                  className="bg-violet-600"
                  color="primary"
                  icon="none"
                  size="xl"
                /> */}

                <a className="text-current visited:text-current no-underline" href="https://forms.gle/dP6H1RJREuYUuhir9" target="_blank">
                  <button className="flex-[0_0_auto] bg-violet-600 hover:bg-violet-700 max-xl:mt-4 md:mr-[22px] max-md:mb-2 px-4 max-md:px-6 py-5 border-none rounded-md md:max-w-[400px] font-bold text-[16px] text-white max-md:leading-[1.3rem] max-md:tracking-[0px] cursor-pointer">
                    Notify Me of the <br className="md:hidden" /> Beta Release!
                  </button>

                </a>

                {/* Student Yourself? Button */}
                {/* <div classname="max-w-[120px] mx-auto">
                  <Button
                    buttonBaseIcon={
                      <EssentialIcon105 className="relative w-6 h-6 text-sm" color="#7C3AED" />
                    }
                    buttonBaseLabelClassNameOverride="text-violet-600 text-sm"
                    buttonBaseText="A Student Yourself? Click Here!"
                    className="border-violet-600 bg-transparent w-full text-violet-600"
                    color="tertiary"
                    icon="leading"
                    size="xl"
                  />
                </div> */}
                <div className="flex justify-center items-center max-xl:mt-3 cursor-pointer">
                  <a href="https://forms.gle/kW4V2MYrmqtdydZSA" target="_blank" className="inline-flex relative justify-center items-center bg-white hover:bg-indigo-600 px-4 py-5 rounded font-semibold text-indigo-600 transition-all duration-150 overflow-hidden ease-in-out group">
                    {/* Background Bar Line */}
                    <span className="group-hover:h-full bottom-0 left-0 z-10 absolute bg-indigo-600 w-full h-1 transition-all duration-150 ease-in-out"></span>
                    {/* Text Span */}
                    <span className="group-hover:text-white relative z-20 w-full [font-family:var(--display-xl-bold-font-family)] text-[16px] text-center max-md:leading-[1.3rem] transition-colors duration-200 ease-in-out">
                      A Student Yourself? <br className="md:hidden" /> Click Here!
                    </span>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* First Section */}

        {/* h-[calc(var(--screen-height-px) * 1.5)] */}
        <div className="flex flex-col justify-center items-center mt-28 mb-24 w-full">
          {/* Section Heading */}
          <div className="inline-flex flex-col mx-auto w-[55%] max-md:w-[65%] text-center max-md:text-left">
            {/* Top Text */}
            <div className="mb-3.5 font-display-lg-bold text-[length:var(--display-lg-bold-font-size)] [font-style:var(--display-lg-bold-font-style)] font-[number:var(--display-lg-bold-font-weight)] text-neutral-900 max-md:text-5xl leading-[var(--display-lg-bold-line-height)] max-md:leading-[1.15em] tracking-[var(--display-lg-bold-letter-spacing)]">
              The <br className="md:hidden" /> All-in-One <br className="md:hidden" /> Platform
            </div>
            {/* Bottom Text */}
            <p className="mx-auto max-md:mt-4 max-w-5xl font-normal [font-family:'Inter',Helvetica] text-center text-pretty text-xl max-md:text-lg max-md:text-left leading-relaxed tracking-[0]">
              <span className="font-normal text-neutral-700 max-md:text-balance tracking-[0]">
                Discover the right students, delve into their backgrounds, and understand their unique qualities—all within a single comprehensive system. With{" "}
              </span>
              <span className="font-semibold">Candela</span>
              <span className="font-normal text-neutral-700 tracking-[0]">
                ,{" "}
              </span>
              <span className="font-bold bg-clip-text-pink-to-blue-gradient">one platform is all you need.</span>
            </p>
          </div>
          {/* Section Body */}
          <div className="flex md:flex-row flex-col justify-around mt-16 md:mt-28 px-4 w-[76%] max-md:w-[90%] md:min-w-[768px] max-w-[1400px]">
            {/* Column 1*/}
            <div className="mx-auto md:mx-0 mb-16 md:mb-0 w-[22%] max-md:w-[80%] min-w-44 text-center">
              {/*This is the "team fill svg"*/}
              <img className="mx-auto mb-3.5 w-[120px] h-[120px]" alt="Team Fill SVG" src={teamFill} />
              <div className="mb-4 font-bold [font-family:'Source_Sans_Pro',Helvetica] bg-clip-text-pink-to-lilac-gradient text-center text-xl leading-[22px] tracking-[0]">
                Student Community
              </div>
              <p className="font-normal [font-family:'Source_Sans_Pro',Helvetica] text-balance text-base text-center text-neutral-700 leading-[22px] tracking-[0]">
                Access an extensive database of talented university students seeking opportunities across various domains, all at your fingertips on <span className="font-semibold">Candela</span>.
              </p>
              {/* Note for Beta Version */}
              <p className="mt-10 font-normal [font-family:'Source_Sans_Pro',Helvetica] text-center text-neutral-600 text-pretty text-sm leading-[19px] tracking-[0]">
                <span className="font-semibold">Note:</span> Access to the student community will be available in the future; the beta version is intended to only include resumes that you upload.
              </p>
            </div>
            {/* Column 2*/}
            <div className="mx-auto md:mx-0 mb-12 md:mb-0 w-[22%] max-md:w-[80%] min-w-44 text-center">
              {/* This is the search globe svg */}
              <img className="mx-auto mb-3.5 w-[120px] h-[120px]" alt="Search Globe SVG" src={searchGlobe} />
              <div className="mb-4 font-bold [font-family:'Source_Sans_Pro',Helvetica] bg-clip-text-blue-to-teal-gradient text-center text-xl leading-[22px] tracking-[0]">
                Portfolio Analysis
              </div>
              <p className="font-normal [font-family:'Source_Sans_Pro',Helvetica] text-balance text-base text-center text-neutral-700 leading-[22px] tracking-[0]">
                <span className="font-semibold">Candela’s</span> online research engine meticulously reviews every aspect of an applicant’s portfolio, including course projects and research artifacts, to reveal the depth and breadth of their skill set.
              </p>
            </div>
            {/* Column 3*/}
            <div className="mx-auto md:mx-0 md:mb-0 w-[22%] max-md:w-[80%] min-w-44 text-center">
              {/*This is the "team fill svg"*/}
              <img className="mx-auto mb-3.5 w-[120px] h-[120px]" alt="Bar Chart Fill SVG" src={barChartFill} />
              <div className="mb-4 font-bold [font-family:'Source_Sans_Pro',Helvetica] bg-clip-text-pink-to-violet-gradient text-center text-xl leading-[22px] tracking-[0]">
                Smart Resume Insights
              </div>
              <p className="font-normal [font-family:'Source_Sans_Pro',Helvetica] text-balance text-base text-center text-neutral-700 leading-[22px] tracking-[0]">
                Powered by language models, <span className="font-semibold">Candela</span> provides intelligent summaries of your resume stack and highlights key differentiators among candidates, while empowering you to make informed decisions.
              </p>
            </div>
          </div>
        </div>



        {/* Second Section */}
        <div className="relative flex flex-col items-center bg-black pt-16 pb-[5.5rem] w-[full]">
          {/* Section Heading */}
          <div className="inline-flex flex-col items-center w-[55%] max-md:w-[65%]">
            { /* Top Text */}
            <div className="mb-3 font-bold [font-family:'Inter',Helvetica] bg-clip-text-full-rainbow-gradient text-5xl text-center tracking-[-1.20px]">
              Effortless and Effective
            </div>
            { /* Bottom Text */}
            <p className="max-md:mt-3 max-w-4xl font-normal [font-family:'Source_Sans_Pro',Helvetica] text-[#ffffff] text-center text-xl tracking-normal">
              <span className="font-normal text-[#ffffff] text-xl leading-7 tracking-[0]">
                We streamline the recruitment process, making it{" "}
              </span>
              <span className="font-semibold">simpler, faster, and more efficient</span>
              <span className="font-normal text-[#ffffff] text-xl leading-7 tracking-[0]">
                —allowing you to focus on what truly matters.
              </span>
            </p>
          </div>
          { /* Section Body */}
          <div className="flex md:flex-row flex-col justify-around mt-20 px-4 w-[70%] max-md:w-[90%]">
            {/* Column 1 */}
            <div className="mx-auto w-[30%] max-md:w-[50%] min-w-56 text-balance text-center">
              <img
                className="mx-auto mb-8 w-[127px] h-[127px]"
                alt="Time fast svgrepo"
                src="https://c.animaapp.com/GnD7RgU1/img/time-fast-svgrepo-com-1.svg"
              />
              <div className="mb-4 font-bold [font-family:'Source_Sans_Pro',Helvetica] bg-clip-text-yellow-to-orange-gradient text-center text-xl leading-[22px] tracking-[0]">
                Streamlined Process
              </div>
              <p className="font-normal [font-family:'Source_Sans_3',Helvetica] text-[#ffffff] text-balance text-base text-center leading-6 tracking-[0]">
                <span className="font-extrabold">No more endless scrolling:</span> With a few clicks, several sentences, and a couple of minutes, you’ll receive
                quality results and recommendations tailored to your needs and expectations.
              </p>
            </div>
            {/* Column 2 */}
            <div className="mx-auto max-md:mt-12 w-[30%] max-md:w-[50%] min-w-56 text-balance text-center">
              <img
                className="mx-auto mb-8 max-md:mb-4 w-[138px] h-[125px]"
                alt="List ul alt svgrepo"
                src="https://c.animaapp.com/GnD7RgU1/img/list-ul-alt-svgrepo-com-1.svg"
              />
              <div className="mb-4 font-bold [font-family:'Source_Sans_Pro',Helvetica] bg-clip-text-violet-to-blue-gradient text-center text-xl leading-[22px] tracking-[0]">
                Personalized Search Solutions
              </div>
              <p className="font-normal [font-family:'Source_Sans_3',Helvetica] text-[#ffffff] text-balance text-base text-center leading-6 tracking-[0]">
                <span className="font-extrabold">No more lost gems:</span> Experience precise search results that showcase the best fit candidates,
                ensuring you find the perfect matches efficiently without overlooking any hidden talent.
              </p>

            </div>
          </div>
        </div>


        { /* Third Section */}
        <div className="relative flex flex-col items-center my-24 w-full">
          { /* Section Heading + Body Container */}
          <div className="inline-flex flex-col items-center w-[55%] max-md:w-[70%] max-w-[900px]">
            { /* Heading */}
            <p className="font-bold [font-family:'Inter',Helvetica] text-5xl text-black text-center max-md:text-left max-md:text-[40px] max-md:leading-[2.90rem] tracking-[-1.20px] max-md:tracking-[-0.1px] md:whitespace-nowrap">
              All Project Leaders are Welcome!
            </p>
            { /* Body */}
            <div>
              <p className="mt-16 font-normal [font-family:'Inter',Helvetica] text-[17px] text-gray-900 max-md:text-[16px] leading-7 tracking-[0]">
                <span className="font-normal [font-family:'Inter',Helvetica] text-gray-900 leading-7 tracking-[0]">
                  If you’re a{" "}
                </span>
                <span className="font-bold">researcher/professor/postdoc</span>
                <span className="font-normal [font-family:'Inter',Helvetica] text-gray-900 leading-7 tracking-[0]">
                  {" "}
                  considering students for your research project, or a{" "}
                </span>
                <span className="font-bold">company employer</span>
                <span className="font-normal [font-family:'Inter',Helvetica] text-gray-900 leading-7 tracking-[0]">
                  {" "}
                  hiring university candidates to join your team, you’ve come to the right place! As the developers of{" "}
                </span>
                <span className="font-semibold">Candela</span>
                <span className="font-normal [font-family:'Inter',Helvetica] text-gray-900 leading-7 tracking-[0]">
                  , we’re starting in the Stanford community and invite you to try out our platform. We’ll provide sample
                  resumes and project descriptions should you need them for testing. Screenshots of the user interface will be released by the end of July. </span>
              </p>
              <p className="mt-8 font-normal [font-family:'Inter',Helvetica] text-[17px] text-gray-900 max-md:text-[15px] leading-7 tracking-[0]">
                <span>
                  Please submit the form below to be
                  notified of the beta release.{" "}
                </span>
                <span className="underline">We anticipate launching the full public version of </span>
                <span className="font-semibold underline">Candela</span>
                <span className="underline"> by the end of summer 2024.</span>
              </p>
            </div>
          </div>
          <div className="flex md:flex-row flex-col justify-around mt-24 1.5xl:w-[55%] max-w-[900px]">
            <div className="mx-auto my-auto max-md:mb-6 w-[50%]">
              {/* w-[377px] h-[53px]*/}
              {/* <Button
                buttonBaseLabelClassName="!text-[#ffffff]"
                buttonBaseSizeSmIconNoneClassName="!mr-[-7.00px] !mt-[-1.50px] !ml-[-7.00px] !mb-[-1.50px] !flex-[0_0_auto]"
                buttonBaseText="Get Me Notified of the Beta Version Release!"
                className="!flex !bg-violet-600 !w-[377px] !h-[53px]"
                color="primary"
                icon="none"
                size="xl"
              /> */}
              <a className="text-current visited:text-current no-underline" href="https://forms.gle/dP6H1RJREuYUuhir9" target="_blank">
                <button className="flex-[0_0_auto] bg-violet-600 hover:bg-violet-700 mx-auto px-4 py-5 border-none rounded-md max-w-[400px] font-bold text-[16px] text-white max-md:text-[14.5px] max-md:leading-[1.3rem] cursor-pointer">
                  Get Me Notified of the Beta Version Release!
                </button>
              </a>
            </div>
            <div className="mx-auto w-[50%]">
              <p className="font-normal [font-family:'Inter',Helvetica] text-black text-sm max-md:text-[12px] leading-6 max-md:leading-5 tracking-[0]">
                <span className="italic">If you have any questions or comments, please contact us at </span>
                <a href="mailto:info@candela.fit" rel="noopener noreferrer" target="_blank">
                  <span className="underline italic">info@candela.fit</span>
                </a>
                <span className="italic">
                  .<br />
                  <br />
                  We have quick response times!
                </span>
              </p>
            </div>
          </div>
        </div>

        { /* Footer Section */}
        {/* w-[1441px] */}
        <footer className="relative bg-[#f2f7ffb2] backdrop-blur-[100px] backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(100px)_brightness(100%)] rounded-[10px_10px_0px_0px] w-full h-[180px] overflow-hidden">
          {/* Background Image */}
          {/* <img
            className="absolute w-full h-full"
            alt="Blended Circles"
            src="https://c.animaapp.com/GnD7RgU1/img/frame-8.svg"
          /> */}
          {/* <img
            className="top-0 absolute w-auto max-w-none h-auto max-h-none transform -translate-x-1/2 image scale-[1.5]"
            alt="Blended Circles"
            src="https://c.animaapp.com/GnD7RgU1/img/frame-8.svg"
          /> */}
          <div className="relative bg-[100%_100%] bg-[url(https://c.animaapp.com/GnD7RgU1/img/frame-8.svg)] bg-cover w-full h-full saturate-100">
            <div className="flex flex-col justify-center items-center h-full">
              {/* Candela and Caption */}
              <div className="text-center">
                {/* <div className="font-normal [font-family:'Allura',Helvetica] text-6xl text-neutral-900 max-md:text-[3rem] leading-[72px] max-md:leading-[50px] tracking-[3.00px]"> */}
                <div className="text-[45px] max-md:text-[3rem] leading-[72px] max-md:leading-[50px] tracking-[0.00px] playwrite-hr-font">
                  Candela
                </div>
                <p className="font-normal [font-family:'Inria_Sans',Helvetica] text-[#6c768b] text-lg max-md:text-[1rem] italic leading-7 tracking-[0.36px] whitespace-nowrap">
                  A project by students @ Stanford
                </p>
              </div>
              {/* Copyright and All Rights Reserved part */}
              <div>
                <p className="right-0 bottom-0 absolute mr-3 mb-3 font-normal [font-family:'Calibri-Regular',Helvetica] text-[#6c768b] text-[15px] text-center max-md:text-[12px] leading-5 tracking-[0] whitespace-nowrap">
                  <span className="font-normal [font-family:'Calibri-Regular',Helvetica] text-[#6c768b] leading-5 tracking-[0]">
                    © 2024{" "}
                  </span>
                  <span className="[font-family:'Calibri-Regular',Helvetica]">
                    Candela
                  </span>
                  <span className="font-normal [font-family:'Calibri-Regular',Helvetica] text-[#6c768b] leading-5 tracking-[0]">
                    . All rights reserved.
                  </span>
                </p>
              </div>
              {/* Desktop Version of Logo */}
              <img
                className="left-[15%] absolute max-md:hidden bg-blend-darken my-auto w-[125px] h-[125px]"
                alt="Butterflies logo"
                src={butterflyImage}
              />
              {/* Mobile Version of Logo */}
              <img
                className="-bottom-2 -left-3 absolute md:hidden bg-blend-darken my-auto w-[75px] h-[75px]"
                alt="Butterflies logo"
                src={butterflyImage}
              />
            </div>
          </div>
        </footer>


      </div >
    </div >
  );
};

export default Desktop;
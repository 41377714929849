import "../../../global.css"; // Adjust path as necessary
import React from "react";
import ReactDOM from "react-dom/client"; // Import from react-dom/client
import Desktop from "./Desktop";
import { initializeScreenDimensionUpdates } from "../../utils/getScreenDims";

const rootElement = document.getElementById("app");
const root = ReactDOM.createRoot(rootElement);

initializeScreenDimensionUpdates(); // Initialize screen dimension updates

root.render(
  <React.StrictMode>
    <Desktop />
  </React.StrictMode>
);

export { Desktop } from "./Desktop";

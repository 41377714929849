/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { EssentialIcon84 } from "../../icons/EssentialIcon84";
import { IconCircle } from "../../icons/IconCircle";

export const ButtonBase = ({
  size,
  icon,
  className,
  labelClassName,
  text = "Button",
  override = <IconCircle className="!relative !w-6 !h-6" color="white" />,
  labelClassNameOverride,
  divClassName,
  essentialIcon84Color = "white",
  essentialIcon84Stroke = "white",
  iconCircleColor = "white",
  iconCircleStroke = "white",
}) => {
  return (
    <div
      className={`inline-flex items-center gap-2 rounded-lg justify-center relative ${size === "md" && ["leading", "none", "trailing"].includes(icon)
        ? "px-4 py-2.5"
        : icon === "only" && ["sm", "xs"].includes(size)
          ? "p-2"
          : size === "md" && icon === "only"
            ? "p-2.5"
            : size === "lg" && ["leading", "none", "trailing"].includes(icon)
              ? "px-5 py-3"
              : size === "lg" && icon === "only"
                ? "p-3"
                : size === "xl" && ["leading", "none", "trailing"].includes(icon)
                  ? "px-6 py-4"
                  : size === "xl" && icon === "only"
                    ? "p-4"
                    : "px-3 py-2"
        } ${className}`}
    >
      {icon === "leading" && <>{override}</>}

      {["leading", "none", "trailing"].includes(icon) && (
        <div
          className={`w-fit mt-[-1.00px] relative text-center whitespace-nowrap ${size === "xs"
            ? "font-body-xs-semibold"
            : ["lg", "xl"].includes(size)
              ? "font-body-md-semibold"
              : "font-body-sm-semibold"
            } ${size === "xs"
              ? "tracking-[var(--body-xs-semibold-letter-spacing)]"
              : ["lg", "xl"].includes(size)
                ? "tracking-[var(--body-md-semibold-letter-spacing)]"
                : "tracking-[var(--body-sm-semibold-letter-spacing)]"
            } ${size === "xs"
              ? "text-[length:var(--body-xs-semibold-font-size)]"
              : ["lg", "xl"].includes(size)
                ? "text-[length:var(--body-md-semibold-font-size)]"
                : "text-[length:var(--body-sm-semibold-font-size)]"
            } ${size === "xs"
              ? "[font-style:var(--body-xs-semibold-font-style)]"
              : ["lg", "xl"].includes(size)
                ? "[font-style:var(--body-md-semibold-font-style)]"
                : "[font-style:var(--body-sm-semibold-font-style)]"
            } ${size === "xs"
              ? "font-[number:var(--body-xs-semibold-font-weight)]"
              : ["lg", "xl"].includes(size)
                ? "font-[number:var(--body-md-semibold-font-weight)]"
                : "font-[number:var(--body-sm-semibold-font-weight)]"
            } ${size === "xs"
              ? "leading-[var(--body-xs-semibold-line-height)]"
              : ["lg", "xl"].includes(size)
                ? "leading-[var(--body-md-semibold-line-height)]"
                : "leading-[var(--body-sm-semibold-line-height)]"
            } ${labelClassName} ${labelClassNameOverride} ${divClassName}`}
        >
          {text}
        </div>
      )}

      {((icon === "only" && size === "lg") ||
        (icon === "only" && size === "md") ||
        (icon === "only" && size === "sm") ||
        (icon === "only" && size === "xs") ||
        (icon === "trailing" && size === "lg") ||
        (icon === "trailing" && size === "md") ||
        (icon === "trailing" && size === "sm") ||
        (icon === "trailing" && size === "xs")) && (
          <EssentialIcon84
            className={size === "xs" ? "!relative !w-4 !h-4" : "!relative !w-5 !h-5"}
            color={icon === "only" ? essentialIcon84Stroke : essentialIcon84Color}
          />
        )}

      {size === "xl" && ["only", "trailing"].includes(icon) && (
        <IconCircle className="!relative !w-6 !h-6" color={icon === "trailing" ? iconCircleColor : iconCircleStroke} />
      )}
    </div>
  );
};

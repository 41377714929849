// src/utils/getScreenDims.js

export const getCurrentHeight = () => {
  return window.innerHeight;
};

export const getCurrentWidth = () => {
  return window.innerWidth;
};

export const updateScreenDimensions = () => {
  document.documentElement.style.setProperty('--screen-height-px', `${getCurrentHeight()}px`);
  document.documentElement.style.setProperty('--screen-width-px', `${getCurrentWidth()}px`);
};

export const initializeScreenDimensionUpdates = () => {
  updateScreenDimensions(); // Set initial values

  window.addEventListener('resize', updateScreenDimensions); // Update on window resize

  return () => {
    window.removeEventListener('resize', updateScreenDimensions);
  };
};
